.structure-template-table {
    width: 100%;
    max-width: 400px;
    margin-top: 4px;
}

.structure-template-table tbody, .structure-template-table thead {
    margin-top: 5px;
    display: block;
}

.structure-template-table thead {
    border-bottom: 1px solid black;
}

.structure-template-table tbody {
    font-size: 12px;
 }

 .structure-template-table tr .form-check {
    top: -10px;
 }

 .roi-name-column.not-allowed {
     font-style: italic;
     color: rgb(46, 45, 45);

 }