.grading-in-progress {
    color: rgb(15, 135, 165);
}

.grading-ready-for-next-phase {
    color: rgb(15, 135, 165);
}

.grading-waiting-for-peer-review {
    color: rgb(184, 137, 9);
}

.grading-waiting-for-review {
    color: rgb(184, 137, 9);
}

.grading-peer-fixes-needed {
    color: rgb(175, 33, 33);
}

.grading-fixes-needed {
    color: rgb(175, 33, 33);
}

.grading-peer-reviewed {
    color: rgb(34, 158, 107);
}

.grading-reviewed {
    color: rgb(85, 155, 39);
}

.grading-ready-for-training {
    color: rgb(19, 107, 48);
}

.grading-expert-approved {
    color: rgb(19, 107, 48);
}

.grading-obsolete {
    color: rgb(126, 126, 126);
}

.grading-rutherford-undefined {
    color: rgb(126, 126, 126);
}

.grading-rutherford-approved {
    color: rgb(85, 155, 39);
}

.grading-rutherford-unapproved {
    color: rgb(175, 33, 33);
}

.grading-default {
    color: inherit;
}
