.plane-view {
   /* border-style:solid;
   border-color:blue; */
   position: absolute;
   width: 100%;
   height: 100%;
}

.crosshair-cursor:hover{
    cursor: url('../../icons/crosshair.svg') 10 9, auto;	
}
.crosshair-draw-cursor:hover{
    cursor: url('../../icons/crosshair-add.svg') 10 9, auto;	
}
.crosshair-erase-cursor:hover{
    cursor: url('../../icons/crosshair-remove.svg') 10 9, auto;	
}
.pointer-cursor:hover{
    cursor: pointer;
}
.pencil-cursor:hover{
    cursor: url('../../icons/grommet-edit.png'), auto;	
}
.eraser-cursor:hover{
    cursor: url('../../icons/grommet-erase.png'), auto;	
}

/* Top-right corner button to expand the plane view */
.expand-plane-svg {
    position:absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 22px;
    opacity: 0.45;
}
.expand-plane-svg:hover {
    opacity: 0.3;
}
.expand-plane-button {
    width: 100%;
    height: 100%;
    fill: white;
}
.expand-plane-rectangle {
    width: 60%;
    height: 45%;
    stroke-width:2;
    fill: rgba(0, 0, 0, 0);
    stroke:black;
}


/* Overlay texts on top of the plane view */
.overlay {
    z-index: -1;
    position:absolute;
    color: white;
    font-size: 13px;

    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.overlay-on-hover {
    padding: .5em;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.overlay-top-right{
    top:0px;
    right:9px;
}    
.overlay-top-left {
    top:0px;
    left:9px;
}
.overlay-bottom-right{
    text-align: right;
    width: 450px;
    bottom:5px;
    right:9px;
}
.overlay-bottom-right-slice{
    bottom:25px;
    right:9px;
}
.overlay-bottom-left {
    bottom:5px;
    left:9px;
}

.overlay-disclaimer{
    background-color: black;
    top:6%;
    left:50%;
    -webkit-transform: translate(-50%, 0);
}

.overlay-top{
    top:15px;
    left:50%;
    -webkit-transform: translate(-50%, 0); 
}
.overlay-right{
    right:15px;
    top:50%;
    -webkit-transform: translate(0, -50%); 
}
.overlay-bottom{
    bottom:15px;
    left:50%;
    -webkit-transform: translate(-50%, 0); 
}
.overlay-left{
    left:15px;
    top:50%;
    -webkit-transform: translate(0, -50%); 
}

.popup {
    position: absolute;
    left: 1%;
    right: 25%;
    top: 1%;
    /*bottom: 25%;*/
    margin: auto;
    background: white;
    opacity: 0.25;
}
.popup:hover {
    position: absolute;
    left: 1%;
    right: 25%;
    top: 1%;
    /*bottom: 25%;*/
    margin: auto;
    background: white;
    opacity: 0.9;
}
