.pulsing {
    animation: pulse 0.8s infinite alternate ease;
}

@keyframes pulse {
    from { opacity: 0.3; }
    to { opacity: 0.9; }
}

.pop-in {
    animation: pop-in 0.5s ease 0s 1 normal forwards;
}

@keyframes pop-in {
    from { opacity: 0.5 }
    to { opacity: 1.0; }
}

.pop-out {
    animation: pop-out 0.8s ease-out 0s 1 normal forwards;
}

@keyframes pop-out {
    from { opacity: 1.0 }
    to { opacity: 0.0; }
}