.global-main-toolbar {
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    margin-right: 10px;
}

.global-main-toolbar>.toolbar-container {
    margin-left: auto;
}

.global-toolbar-buttons button>svg {
    /* ensure icons in toolbar are vertically centered */
    margin-bottom: 1px;
}

.toolbar-main-container button {
    background-color: #fff;
}