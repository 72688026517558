.model-mapping-page .csv-paste-area {
    width: 800px;
}

.model-mapping-page .generated-json {
    border: 1px solid rgb(172, 172, 172);
    border-radius: 2px;
    padding: 3px 8px;
    color: rgb(83, 82, 82);
    background-color: rgb(245, 243, 243);
}

.model-mapping-page {
    padding: 10px;
}

.model-mapping-page .generated-options span {
    padding: 5px;
}

.model-mapping-page .generated-options {
    margin: 10px 0;
}

.model-mapping-page .supported-model-name {
    color: #009900;
}

.model-mapping-page .unsupported-model-name {
    color: #cc0000;
}

.model-mapping-page .unsupported-header {
    background-color: #ff9999;
}

.model-mapping-page .unsupported-column {
    background-color: #ff9999;
}