.local-page-not-for-clinical {
    color: red;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 30px;
}

.local-page-container {
    width: 100vw;
    height: auto;
    margin-top: 20px;
    margin-left: 20px;
}

.image-form {
    margin-bottom: 20px;
}

.rtstruct-form {
    margin-bottom: 35px;
}

.version-number {
    margin-top: 30px;
    font-size: 12px;
}

.global-toolbar-for-local {
    float: right;
    margin-top: -18px;
    margin-right: 10px;
}

.file-load-errors-alert {
    max-width: 1100px;
}

.file-load-errors {
    display: flex;
}

.file-load-errors .filename {
    width: 300px;
}

.hide-file-form {
    display: none;
}

/*.popup {*/
/*    position: fixed;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    margin: auto;*/
/*    background-color: rgba(0,0,0, 0.5);*/
/*}*/
.popup {
    position: absolute;
    left: 1%;
    right: 25%;
    top: 1%;
    /*bottom: 25%;*/
    margin: auto;
    background: white;
    opacity: 0.25;
}

.popup:hover {
    position: absolute;
    left: 1%;
    right: 25%;
    top: 1%;
    /*bottom: 25%;*/
    margin: auto;
    background: white;
    opacity: 0.9;
}

.home-panel {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 10px;
    max-width: 700px;
    padding: 60px 40px;
}

.home-logo {
    margin-bottom: 60px;
}

.home-panel .subtitle {
    font-size: 12px;
}

.scan-progress-bar {
    margin-top: 10px;
}

.home-page>.panels {
    display: flex;
    justify-content: space-between;
    z-index: 2;
}

.home-page .image-panel-container {
    align-self: center;
    flex-grow: 1;
}

.image-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.home-carousel {
    width: 800px;
}

.home-carousel img {
    width: 800px;
}

.caption-box {
    height: 150px;
    text-align: center;
}

.caption-box .caption {
    color: #fff;
    padding: 20px;
    background-color: #000;
    width: 800px;
    min-height: 130px;
}

.local-background {
    background: url("/img/background2-flipped.jpg") no-repeat center center fixed;;
    background-color: rgb(207, 207, 207);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.local-foreground {
    z-index: 2;
    position: relative;
}