.help-dialog-markdown td, .help-dialog-markdown th {
    padding-right: 30px;
}

.help-dialog-markdown table {
    margin-bottom: 15px;
}

.help-dialog-markdown h3 {
    margin-top: 15px;
}
