.model-text-entry {
    margin-top: 5px;
}

.flash-on-reveal {
    animation: flash-border-colors 1.5s cubic-bezier(.17,.67,.83,.67);
}

@keyframes flash-border-colors {
    0% {
        background-color: #fdfcdd;
    }
    40% {
        background-color: #fffa93;
    }
    100% {
        background-color: inherit;
    }
}

span.autocontour-in-progress {
    font-size: smaller;
}

/* styles for validation */
span.invalid-prediction-model {
    font-size: smaller;
    color: #ee892c;
}

span.autocontour-in-progress.autocontour-not-available {
    color: #ee892c;
}