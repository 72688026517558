.live-review-info {
    margin-top: 30px;
    margin-left: 30px;
}

.query-label {
    width: 185px;
}

.config-item {
    margin-bottom: 5px;
}
.config-label {
    margin-right: 5px;
    width: 180px;
}
.config-value {
    margin-right: 5px;
    width: 300px;
}
