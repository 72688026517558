.service-agreement .summary {
    margin-top: 30px;
    font-size: 14px;
}

.service-agreement table.metadata-table, 
.service-agreement .metadata-table th, 
.service-agreement .metadata-table td {
    border: 1px solid black;
    border-collapse: separate;
}

.service-agreement table.metadata-table {
    margin-bottom: 20px;
}

.service-agreement .invisible-divider {
    margin-bottom: 60px;
}
