.dataset-category {
    font-size: larger;
    margin-top: 3px;
    margin-bottom: 10px;
}

.dataset-badge {
    padding: 3px 5px;
    padding-top: 4px;
}

.dataset-category .category-icon {
    position: relative;
    top: -1.3px;
    margin-left: 1px;
}

.dataset-category .category-text {
    margin: 2px 3px;
}
