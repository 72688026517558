.position-dragger{
    position: absolute !important;
}

.position-dragger-vertical{
    height: 100%;
    width: 12px;
    -webkit-transform: translate(-50%, 0); 
}
.position-dragger-horizontal{
    width: 100%;
    height: 12px;
    -webkit-transform: translate(0, -50%); 
}

.position-dragger-box{
    opacity: 0.6;
    width: 12px !important;
    height: 12px !important;
    position: absolute !important;
    cursor: move;
    z-index: 2;
}
.position-dragger-box-top{
    left: 0px;
    top: 0px;
}
.position-dragger-box-bottom{
    left: 0px;
    bottom: 0px;
}

.position-dragger-box-left{
    left: 0px;
    top: 0px;
}
.position-dragger-box-right{
    right: 0px;
    top: 0px;
}

.position-dragger-box-svg {
    position: absolute;
    width: 12px;
    height: 12px;
}

.position-dragger-box-rect {
    width: 12px;
    height: 12px;
    fill: red;
}

.position-dragger-line-vertical {
    height: 100%;
}
.position-dragger-line-horizontal {
    width: 100%;
}
.position-dragger-line-svg {
    opacity: 0.4;
    height: 100%;
    width: 100%
}
.position-dragger-line-path {
    stroke-width: 1px;
    stroke: white;
    opacity: 0.75;
}
