
.canvas-grid {
    position: relative !important;
    /* border-style:solid;
    border-color:brown; */
    
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr ;
}
  
.plane-views {
    grid-column: 1;
    grid-row: 1;
    z-index: 1;
}
  
.canvas-wrapper {
    position: relative !important;
    left: 0;
    top: 0;
    grid-column: 1;
    grid-row: 1;
    z-index: 0;
}

.canvas-wrapper canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.canvas-grid-left-area {
    height: 100% !important;
    width: 100% !important; 
    /* border-style:solid;
   border-color:blue;  */
}

/* .canvas-grid-right-area {
    border-style:solid;
   border-color:red; 
} */

.right-top-area {
    /* border-style:solid;
   border-color:red;  */
   height: 100% !important;
   width: 100% !important;
}

.right-bottom-area {
    height: 100% !important;
    width: 100% !important;
    /* border-style:solid;
   border-color:green;  */
}

.canvas-grid .Resizer {
    background: white;
    opacity: 0.25;
    z-index: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.canvas-grid .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.canvas-grid .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.canvas-grid .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.canvas-grid .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.canvas-grid .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
} 