.ReactModal__Overlay {
    /* this style override for react-sliding-pane & react-modal allows the sliding panel to draw over other content (incl. rt viewer) */
    z-index: 10;
}

.batch-job-queue-pane {
    min-width: 400px;
}

.batch-job-queue-pane .slide-pane__close {
    /* this override places the 'close slide pane' to the right of the pane */
    position: absolute !important;
    right: 15px !important;
}

.slide-pane__close svg {
    /* this override allows resizing svg elements in the pane which lets us use custom close icons */
    width: unset !important;
}

.batch-job-list {
    margin-top: 10px;
}