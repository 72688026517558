.batch-job-dialog .prediction-model-selector {
    margin-top: 20px;
}

.batch-job-dialog .instructions {
    margin-bottom: 20px;
}

.batch-job-dialog .confirmation-instructions {
    margin-top: 20px;
    font-weight: 500;
}

.batch-job-dialog .selection-counts {
    margin-top: 10px;
}

.batch-job-dialog .batch-job-selections {
    margin-top: 20px;
}

.batch-job-dialog .selection-row {
    font-size: 14px;
    margin: 1px;
}

.batch-job-dialog .selection-row:nth-child(even) {
    background-color: rgb(231, 231, 231);
}

.batch-job-dialog .selection-row > .batch-operation.auto-contouring-selected {
    font-weight: 600;
}