.dataset-browser {
  margin: 30px;
}

.help-button {
    margin-bottom: 5px;
}

.config-item {
    margin-bottom: 5px;
}

.config-label {
    margin-right: 5px;
    width: 160px;
}

.config-value {
    margin-right: 5px;
    width: 300px;
}

.storage-dropdown {
    margin-right: 5px;
    margin-bottom: 5px;
}

.file-share-select {
  margin-top: 10px;
  font-size: 12px;
}
.file-share-select-item {
  cursor: default;
}
.file-share-select-item:hover {
  font-weight: bold;
}
.file-share-select-item-highlight {
  font-weight: bold;
}

.filter-buttons {
  margin-top: 10px;
  margin-bottom: 5px;
}

.show-batch-controls-button{
  margin-bottom: 30px;
}

.pagination > li {
    display: inline-block;
    padding-left: 0;
  }
  .pagination > li {
    list-style: none;
    border: 0.9px solid;
  }
  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #2c689c;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
  }
  
  .pagination>li.active>a {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  /* Style the active class (and buttons on mouse-over) */
  .pagination > li > a:hover {
    background-color:  #218838;
    color: white;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
      padding: 0px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    display: none!important;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    margin-right: 0;
    padding: 0px!important;
    border-top-right-radius: 4px;
    display: none!important;
  }

.dataset-div {
  margin-top: 0px;
}
.dataset-browser-version {
  font-size: 12px;
  margin-bottom: 10px;
}