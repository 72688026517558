.visible {
    display: inline;
}

.invisible {
    display: none;
}

.clickable-glyph {
    cursor: pointer;
    color: rgb(66, 66, 66);
}

.clickable-glyph:hover {
    color: rgb(12, 12, 12);
}

/* bootstrap overrides */

.dropdown-menu {
    max-height: 800px !important;
    overflow-y: auto  !important;
}