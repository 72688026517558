.margin-source-roi {
    margin-bottom: 7px;
}
.margin-target-roi {
    margin-bottom: 7px;
}
.margin-row {
    margin-bottom: 5px;
}
.margin-label {
    width: 150px;
    display: inline-block;
}
.margin-checkboxes {
    margin-top: 20px;
    margin-bottom: 20px;
}
.margin-inputs {
    margin-top: 10px;
    margin-bottom: 10px;
}
.margin-dialog-buttons {
    margin-top: 30px;
}
.margin-error{
    margin-top: 20px;
    font-weight: bold;
    color: red;
}