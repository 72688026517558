.loading-backups {
    display: flex;
}

.backup-loading-text {
    margin-left: 20px;
}

.backup-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backup-file-name {
    flex-grow: 1;
}

.restored-checkmark {
    width: 35px;
}
