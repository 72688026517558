.unsupported-container {
    width: 100vw;
    height: 100vh;
    margin-top: 20px;
    margin-left: 20px;
}

.unsupported-error {
    color: red;
    font-weight: bold;
    margin-bottom: 30px;
}


