.list-select {
    padding: 5px 30px 10px 5px;
    width: 100%;
    font-size: 14px;
}

.list-select option {
    padding: 1px 50px 2px 5px;
}

.list-select option:hover {
    background-color: rgb(224, 236, 241);
}

.list-select:focus {
    /* remove visual indication of focus since you can't use keys to select items inside the list-select anyway */
    outline: 0;
}