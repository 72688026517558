

.preferences {
    margin-left: 0px;
}

.pref-item {
    width: 360px;
}

.pref-label {
    width: 160px;
    white-space: nowrap;
}
.pref-value {
    width: 60px;
}
.main-toolbar {
    user-select: none;
}


.main-toolbar .toolbar {
    margin-top: 2px;
    margin-bottom: 2px;
}

.brush-width-value {
    margin-left: 5px; 
    margin-right: 10px;
    width: 50px;
}

.tool-area {
    padding-left: 15px;
}

.global-context-buttons {
    margin-left: auto;
    margin-right: 10px;
}

.toolbar-top {
    display: flex;
}

.disclaimer-not-for-clinical-use {
    margin-top: 2px;
    margin-left: 10px;
    font-weight: bold;
    color: red;
}
