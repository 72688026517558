.checklist-section {
    left: 4px;
}

.add-rois-from-template {
    min-height: 500px;
}

.add-rois-from-template .section-title {
    margin-bottom: 8px;
}